/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.dropzone-image-preview {
  max-width: 100px;
}

.dropzone-image-preview-box {
  float: left; 
}

tr:focus {
  background: LightGray;
}

.paragraph-table-row:hover {
  /* border-left: 3px solid #4338ca; */
  border-inline-start: 3px solid #4338ca;
  writing-mode: horizontal-tb;
}

.paragraph-table-row {
  /* border-left: 3px solid transparent; */
  border-inline-start: 3px solid transparent;
  writing-mode: horizontal-tb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}